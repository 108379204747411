import PropTypes from 'prop-types';

const ssoIntegrationOptionsShape = PropTypes.shape({
  forceSignIn: PropTypes.bool,
  rememberMe: PropTypes.shape({
    display: PropTypes.bool,
    value: PropTypes.bool,
    disable: PropTypes.bool,
  }),
});

export default ssoIntegrationOptionsShape;
