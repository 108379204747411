import qs from 'qs';

const getInitialValuesFromQuery = (query, defaults = {}) => {
  const params = qs.parse(query, { ignoreQueryPrefix: true });
  return {
    ...defaults,
    ...params,
  };
};

export default getInitialValuesFromQuery;
