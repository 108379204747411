import React from 'react';
import PropTypes from 'prop-types';
import { FormText, FormFeedback, FormGroup, Label } from 'reactstrap';

const BaseInput = (props) => {
  const {
    children,
    childImplementsOwnLabel,
    errorMessage,
    successMessage,
    helpText,
    hideLabel,
    id,
    label,
    onChange,
    ...rest
  } = props;
  const errorId = `${id}_error`;
  const successId = `${id}_success`;
  const helpTextId = `${id}_help`;
  const describedBy = [
    (errorMessage ? errorId : null),
    (successMessage ? successId : null),
    (helpText ? helpTextId : null),
  ].filter(Boolean).join(' ') || null;
  const childProps = {
    ...rest,
    id,
    label,
    onChange,
    valid: !!successMessage,
    invalid: !!errorMessage,
    'aria-describedby': describedBy,
    'aria-label': label,
  };
  return (
    <FormGroup>
      {!childImplementsOwnLabel && (
        <Label hidden={hideLabel} htmlFor={id}>
          {label}
        </Label>
      )}
      {typeof children === 'function'
          ? children(childProps)
          : React.cloneElement(children, childProps)
      }
      {errorMessage && (
        <FormFeedback
          className="d-block"
          id={errorId}
          valid={false}
        >
          {errorMessage}
        </FormFeedback>
      )}
      {successMessage && (
        <FormFeedback
          valid
          className="d-block"
          id={successId}
        >
          {successMessage}
        </FormFeedback>
      )}
      {helpText && (
        <FormText id={helpTextId}>{helpText}</FormText>
      )}
    </FormGroup>
  );
};

BaseInput.childPropTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

BaseInput.propTypes = {
  ...BaseInput.childPropTypes,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

BaseInput.defaultChildProps = {
  errorMessage: null,
  successMessage: null,
  helpText: null,
  hideLabel: false,
  onChange() {},
};

BaseInput.defaultProps = {
  children: null,
  childImplementsOwnLabel: false,
  ...BaseInput.defaultChildProps,
};

export default BaseInput;
