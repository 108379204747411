import React from 'react';
import PropTypes from 'prop-types';
import { signOutAndRedirect } from 'redux-modules/auth/actions';
import { connect } from 'react-redux';
import AuthWrapper from 'components/AuthWrapper';
import * as selectors from '../../redux-modules/auth/selectors';

class SignOut extends React.Component {
  componentDidMount() {
    const {
      signOutAndRedirectConnect,
      redirectUri,
      redirectState,
      responseType,
      clientId
    } = this.props;

    signOutAndRedirectConnect(redirectUri, responseType, redirectState,clientId);
  }

  render() {
    const { user, redirectUri } = this.props;

    if (!user && redirectUri) {
      return <div />;
    }

    return <AuthWrapper title="You are being signed out..." />;
  }
}

SignOut.propTypes = {
  signOutAndRedirectConnect: PropTypes.func.isRequired,
  redirectUri: PropTypes.string,
  redirectState: PropTypes.string,
  responseType: PropTypes.string,
};

SignOut.defaultProps = {
  redirectUri: undefined,
  redirectState: undefined,
  responseType: undefined,
};

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
});
const mapDispatchToProps = {
  signOutAndRedirectConnect: signOutAndRedirect,
};
const ConnectedSignOut = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignOut);

export default ConnectedSignOut;
export { SignOut as PureSignOut };
