import Store from '../store';

/*

  Amplify doesn't continuously check if the tokens in
  localstorage are still present. In the event someone
  signs out in another tab, we want all instances of the
  HPA to sign out. This code rechecks for the access token
  in localstorage (no API call necessary) every N seconds
  and will end the session if the tokens are missing.

*/

const ROUTE_DEFAULT = '/sign-in';
const session = {
  check() {
    const checkDelay = 1000 * 1; // recheck every 1 second;
    const store = Store.getState();

    if (store.auth.user) {
      const { clientId, username } = store.auth.user;
      const accessTokenId = `CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`;
      const localAccessTokenId = localStorage.getItem('accessTokenId');
      const { pathname } = window.location;
      if (localAccessTokenId === null && pathname !== ROUTE_DEFAULT) {
        window.location.replace(ROUTE_DEFAULT);
      }
      clearTimeout(session.timeout);
      session.timeout = setTimeout(session.check, checkDelay);
    }
  },
};

export default session;
