import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const RadioButtonForm = ({ options, selectedOption, onChange }) => {
    return (
        <>
            {options.map((option, index) => (
                <FormGroup key={index} check>
                    <Label check>
                        <Input
                            type="radio"
                            name="verificationOption"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={() => onChange(option.value)}
                        />
                        {' '}
                        {option.label}
                    </Label>
                </FormGroup>
            ))}
        </>
    );
};

export default RadioButtonForm;
