import axios from 'axios';
import store from 'store';

async function getFeature() {
    try {
        
        const state = store.getState();
        const { identityBaseUrl } = state.auth.aws;
        const fetchUrl = `${identityBaseUrl}/manage/feature`;
        return axios.get(fetchUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((data) => { 
                
                
                return data.data;})
    } catch (error) {
        throw new Error('Error loading features');
    }
}

export default getFeature;
