import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../../core/Input';
import { required as requiredValidator } from './validate';

const TextFieldComponent = (props) => {
  const {
    input, meta, disabled, label, helpText,
  } = props;
  const { touched, error } = meta;
  const errorMessage = touched && error ? error : '';
  return (
    <Input
      id={input.name}
      type="text"
      label={label}
      helpText={helpText}
      disabled={disabled}
      errorMessage={errorMessage}
      {...input}
    />
  );
};

const TextField = ({
  name, label, disabled, required, helpText,
}) => (
  <Field
    name={name}
    component={TextFieldComponent}
    disabled={disabled}
    helpText={helpText}
    label={label}
    validate={required ? [requiredValidator] : undefined}
  />
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helpText: PropTypes.string,
};

TextField.defaultProps = {
  disabled: false,
  required: false,
  helpText: undefined,
};

export default TextField;
export { TextFieldComponent };
