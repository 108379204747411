import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

const Checkbox = ({
  children,
  checked,
  inline,
  value,
  name,
  onChange,
}) => (
  <FormGroup check inline={inline}>
    <Label check>
      <Input
        type="checkbox"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span />
      {children}
    </Label>
  </FormGroup>
);

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  inline: PropTypes.bool,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  inline: false,
  name: null,
  onChange: () => {},
};

export default Checkbox;
