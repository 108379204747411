import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../../core/Input';
import { isVerificationCode, required as requiredValidator } from './validate';

const VerificationFieldComponent = (props) => {
  const {
    input, meta, disabled, label, helpText,
  } = props;
  const { touched, error } = meta;
  const errorMessage = touched && error ? error : '';
  return (
    <Input
      id={input.name}
      type="text"
      label={label}
      helpText={helpText}
      disabled={disabled}
      errorMessage={errorMessage}
      {...input}
    />
  );
};

const VerificationField = ({
  name, label, disabled, required, helpText,
}) => (
  <Field
    name={name}
    component={VerificationFieldComponent}
    disabled={disabled}
    helpText={helpText}
    label={label}
    validate={required ? [requiredValidator, isVerificationCode] : [isVerificationCode]}
  />
);

VerificationField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helpText: PropTypes.string,
};

VerificationField.defaultProps = {
  disabled: false,
  required: false,
  helpText: undefined,
};

export default VerificationField;
export { VerificationFieldComponent };
