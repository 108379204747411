import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import HStreamIDInput from '../Inputs/HStreamIDInput';
import { isPhoneOrEmail, required } from './validate';

const HStreamIDFieldComponent = (props) => {
  const {
    input, meta, disabled, label, helpText, id
  } = props;
  const { touched, error } = meta;
  const errorMessage = touched && error ? error : '';
  return (
    <HStreamIDInput
      id={id}
      type="text"
      label={label}
      helpText={helpText}
      disabled={disabled}
      errorMessage={errorMessage}
      {...input}
    />
  );
};

const HStreamIDField = ({
  name,
  label,
  disabled,
  helpText,
  id,
}) => (
    <Field
      name={name}
      component={HStreamIDFieldComponent}
      disabled={disabled}
      label={label}
      validate={[required, isPhoneOrEmail]}
      helpText={helpText}
      id={id}
    />
  );

HStreamIDField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  helpText: PropTypes.string,
};

HStreamIDField.defaultProps = {
  id: undefined,
  disabled: false,
  label: 'hStream ID',
  helpText: '',
};

export default HStreamIDField;
export { HStreamIDFieldComponent };
