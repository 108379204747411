import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import IconButton from '../IconButton';
import BaseInput from '../BaseInput';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    const { displayPassword } = props;
    this.controlledToggle = displayPassword !== undefined;
    if (!this.controlledToggle) {
      this.state = {
        displayPassword: false,
      };
      this.onToggle = this.onToggle.bind(this);
    }
  }

  onToggle() {
    this.setState({ displayPassword: !this.state.displayPassword });
  }

  render() {
    let displayPassword;
    let type;
    let onToggle;
    if (this.controlledToggle) {
      ({ displayPassword, onToggle } = this.props);
      type = displayPassword ? 'text' : 'password';
    } else {
      ({ displayPassword } = this.state);
      ({ onToggle } = this);
      type = displayPassword ? 'text' : 'password';
    }
    const icon = displayPassword ? 'eye' : 'eye-slash';
    const label = displayPassword ? 'Hide Password' : 'Show Password';

    const styles = {
      button: {
        borderColor: '#ced4da',
        borderLeft: 0,
        borderRadius: '0 4px 4px 0',
        marginLeft: '-1px',
      },
      input: {
        borderRight: 0,
      },
    };

    // Do not forward displayPassword/onToggle props to child input
    const { displayPassword: _, onToggle: __, ...renderProps } = this.props;
    return (
      <BaseInput {...renderProps}>
        {(props) => (
          <InputGroup>
            <Input type={type} {...props} style={styles.input} />
            <InputGroupAddon addonType="prepend">
              <IconButton
                label={label}
                className="text-primary"
                icon={icon}
                onClick={onToggle}
                style={styles.button}
              />
            </InputGroupAddon>
          </InputGroup>
        )}
      </BaseInput>
    );
  }
}

PasswordInput.propTypes = {
  ...BaseInput.childPropTypes,
  onToggle: PropTypes.func,
  displayPassword: PropTypes.bool,
};

PasswordInput.defaultProps = {
  ...BaseInput.defaultChildProps,
  onToggle: undefined,
  displayPassword: undefined,
};

export default PasswordInput;
