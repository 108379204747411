import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import HStreamIDField from 'components/fields/HStreamIDField';
import { Button, Form, FormGroup } from 'reactstrap';
import LabelWithLoginInformationTooltip from '../../components/Utilities/LabelWithInformationTooltip';

const tooltipContent = [
  'If you verified by phone, enter the phone number.',
  'If you verified by email, enter the email.',
];

const ResetPasswordForm = ({ isRequestPending, handleSubmit, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <HStreamIDField
      required
      helpText=""
      id="forgot_pw_input"
      label={(
        <LabelWithLoginInformationTooltip
          labelText="Email or Mobile Number"
          tooltipId="forgot_password"
          tooltipContent={tooltipContent}
        />
      )}
      name="hstreamid"
      disabled={isRequestPending}
    />
    <FormGroup>
      <Button block disabled={isRequestPending} color="primary">
        Send Reset Link
      </Button>
    </FormGroup>
  </Form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  isRequestPending: false,
};

const ConnectedResetPasswordForm = reduxForm({
  // form: 'forgot-account',
  form: 'forgot-password',
})(ResetPasswordForm);

export default ConnectedResetPasswordForm;
export { ResetPasswordForm as PureResetPasswordForm };
