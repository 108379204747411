import PropTypes from 'prop-types';

const userShape = PropTypes.shape({
  email: PropTypes.string,
  username: PropTypes.string,
  userPoolId: PropTypes.string,
  clientId: PropTypes.string,
  phone: PropTypes.string,
  given_name: PropTypes.string,
  family_name: PropTypes.string,
  isRegistered: PropTypes.bool,
});

export default userShape;
