import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import userShape from 'shapes/userShape';
import locationShape from 'shapes/locationShape';
import { getUser } from 'redux-modules/auth/selectors';
import { Button } from 'reactstrap';

const Welcome = ({ location, user }) => (
  <div className="Welcome">
    <div className="Welcome__inner">
      This is the placeholder welcome page that is publicly available. It has
      different actions based on the logged in state of the user.
      {user && (
        <div className="Welcome__actions">
          <Link to="/">
            <Button color="white">Get Started</Button>
          </Link>
        </div>
      )}
      {!user && (
        <div className="Welcome__actions">
          <Link to={{ pathname: '/account/register', search: location.search }}>
            <Button color="white">Create an hStream ID</Button>
          </Link>
          <Link to={{ pathname: '/account/sign-in', search: location.search }}>
            <Button color="white">Sign In</Button>
          </Link>
        </div>
      )}
    </div>
  </div>
);

Welcome.propTypes = {
  user: userShape,
  location: locationShape.isRequired,
};

Welcome.defaultProps = {
  user: undefined,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const ConnectedWelcome = connect(mapStateToProps)(Welcome);
export default ConnectedWelcome;
export { Welcome as PureWelcome };
