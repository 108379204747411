require('es6-promise').polyfill();
require('isomorphic-fetch');

async function loadConfig({
  configApiBaseUrl = 'https://config.hstream.net',
  appId = 'hpa',
  envName = 'hspaasdev',
  configName = 'hstreamid',
}) {
  try {
    const fetchUrl = `${configApiBaseUrl}/${appId}/${envName}/${configName}`;
    const response = await fetch(fetchUrl);
    const { message, config } = await response.json();

    if (message) {
      throw new Error(message);
    }

    return { appId, envName, config };
  } catch (error) {
    throw new Error('Error loading config');
  }
}

export default loadConfig;
