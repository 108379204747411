import React from 'react';
import PropTypes from 'prop-types';
import phone from 'phone';
import { Field } from 'redux-form';
import Input from '../../core/Input';
import { isPhoneNumber, matchesPhone, required as requiredValidator } from './validate';

export const PhoneNumberFieldComponent = (props) => {
  const { id, input, meta, disabled, label, helpText } = props;
  const { touched, error } = meta;
  const errorMessage = touched && error ? error : '';
  return (
    <Input
      id={id}
      type="text"
      label={label}
      helpText={helpText}
      disabled={disabled}
      errorMessage={errorMessage}
      {...input}
    />
  );
};

export const normalizePhone = (value) => {
  const result = phone(value, ['USA', 'CAN']);
  const prefixedPlus = phone(`+${value}`, ['USA', 'CAN']);
  if (result.length === 2) {
    const formattedNumber = result[0];
    return formattedNumber;
  }
  if (prefixedPlus.length === 2) {
    const formattedNumber = prefixedPlus[0];
    return formattedNumber;
  }
  return value;
};

const PhoneNumberField = ({
  id,
  name,
  label,
  disabled,
  helpText,
  required,
  confirm,
}) =>
  (confirm ? (
    <Field
      id={id}
      name={name}
      component={PhoneNumberFieldComponent}
      disabled={disabled}
      label={label}
      validate={required ? [requiredValidator, isPhoneNumber, matchesPhone] : [isPhoneNumber, matchesPhone]}
      normalize={normalizePhone}
      helpText={helpText}
    />
  ) : (
    <Field
      id={id}
      name={name}
      component={PhoneNumberFieldComponent}
      disabled={disabled}
      label={label}
      validate={required ? [requiredValidator, isPhoneNumber] : [isPhoneNumber]}
      normalize={normalizePhone}
      helpText={helpText}
    />
  ));

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
};

PhoneNumberField.defaultProps = {
  disabled: false,
  label: 'Phone Number',
  id: 'phone',
  helpText: undefined,
  required: false,
};

export default PhoneNumberField;
