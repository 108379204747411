import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const updatedQuerystring = new URL(window.location.href);
updatedQuerystring.searchParams.delete('code');
updatedQuerystring.searchParams.delete('hstreamid');

const Success = ({queryString}) => (
  <section>
    <h2 className="AuthWrapper__content-headline">
      Your password has been reset.
    </h2>
    <div className="AuthWrapper__content-message">
      <p>You can sign in on the next screen.</p>
    </div>
    <div>
      {queryString && queryString.includes("response_type=code") ? (
        <div className="AuthWrapper__content-action">
          <a href={`/account/authorize${decodeURIComponent(updatedQuerystring.search)}`} target="_self" rel="noopener noreferrer"><Button color="primary" block>
              Continue
            </Button></a>
        </div>
      ) : (
        <div className="AuthWrapper__content-action">
          <Link to="/account/sign-in">
            <Button color="primary" block>
              Continue
            </Button>
          </Link>
        </div>
      )}
    </div>
  </section>
);

export default Success;
