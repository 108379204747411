import React, { useContext } from 'react';
import Moment from 'moment';
import LogOutButton from 'components/LogOutButton';
import PropTypes from 'prop-types';
import HstreamLogo from '../HstreamLogo';
import HpaStoreContext from '../../contexts/HpaStoreContext';

const AuthWrapper = ({
  branding,
  title,
  redirectName,
  children,
  secondary,
  footer,
  logout,
}) => {
  const context = useContext(HpaStoreContext);
  let customBrand = undefined;
  if (context) {
    const customBrandingImage = context.customBranding
      ? context.customBranding.logoUri
      : undefined;
    customBrand = customBrandingImage ? (
      <img
        alt='branding-logo'
        id="custom-brand-logo"
        style={{ maxHeight: '104px', maxWidth: '250px' , width: '100%', height: 'auto'}}
        src={customBrandingImage}
      />
    ) : undefined;
  }

  const imageForBranding = customBrand ? customBrand : branding;

  if (customBrand && secondary) {
    return (
      <div className="AuthWrapper">
        <div className="AuthWrapper__container is-large">
          <div className="AuthWrapper__panel">
            <div className="AuthWrapper__primary">
              <div className="AuthWrapper__branding">
                <div style={{ width: '150px', height: '33px' }}>
                  {imageForBranding}
                </div>
              </div>
              {title && <h2 className="AuthWrapper__title">{title}</h2>}
              {redirectName && (
                <div className="AuthWrapper__redirect">
                  to continue to {redirectName}
                </div>
              )}
              <div className="AuthWrapper__content">{children}</div>
            </div>
            {logout && (
              <div className="AuthWrapper__logout">
                <LogOutButton />
              </div>
            )}
          </div>
          <div className="AuthWrapper__footer">{footer}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="AuthWrapper">
      <div className="AuthWrapper__container is-small">
        <div className="AuthWrapper__panel">
          <div className="AuthWrapper__primary">
            <div className="AuthWrapper__branding">
              <div style={{ width: '300px', height: '104px' }}>
                {imageForBranding}
              </div>
            </div>
            {title && <h2 className="AuthWrapper__title">{title}</h2>}
            {redirectName && redirectName!= "undefined" && (
              <div className="AuthWrapper__redirect">
                to continue to {redirectName}
              </div>
            )}
            <div className="AuthWrapper__content">{children}</div>
            {/* {customBrand && (
              <div
                style={{
                  maxHeight: '150px',
                  maxWidth: '100px',
                  height: '30px',
                  marginLeft: 'auto',
                }}
              ></div>
            )} */}
          </div>
          {logout && (
            <div className="AuthWrapper__logout">
              <LogOutButton />
            </div>
          )}
          <div className="AuthWrapper__footer">{footer}</div>
        </div>
      </div>
    </div>
  );
};

AuthWrapper.propTypes = {
  branding: PropTypes.node,
  logout: PropTypes.bool,
  children: PropTypes.node,
  secondary: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  redirectName: PropTypes.string,
  footer: PropTypes.node,
  bottomRightBranding: PropTypes.bool,
};

AuthWrapper.defaultProps = {
  branding: <div style={{ paddingTop: '25px'}}><HstreamLogo /></div>,
  bottomRightBranding: false,
  logout: false,
  children: null,
  secondary: null,
  title: null,
  redirectName: null,
  footer: (
    <div>
      Copyright &copy; {Moment().year()} HealthStream, Inc.{' '}
      <span>All Rights Reserved.</span>
    </div>
  ),
};

export default AuthWrapper;
