import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import AuthWrapper from 'components/AuthWrapper';
import {
  resendConfirmationCode,
  verifyRegistration,
  authError,
  checkMobilePrompt,
  resendLoginConfirmationCode,
  verifyLoginConfirmationCode,
} from '../../redux-modules/auth/actions';
import * as selectors from 'redux-modules/auth/selectors';
import redirect from 'helpers/redirect';
import VerificationCodeForm from './VerificationCodeForm';
import Success from './Success';
import ResendCodeSelection from '../../components/ResendCodeSelection';
import store from '../../store';
import PhonePrompt from '../PhonePrompt';
import { maskEmail, maskPhoneNumber } from '../../utils/maskingUtils';
import FormatErrorMessage from '../FormatMessage/FormatErrorMessage';

class Confirm extends Component {
  constructor(props) {
    super(props);
    const { search, location } = this.props;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    let { uid, code } = params;
    let email = null;
    let chosenResendOption;
    if (location.state && location.state.username) {
      const { email: passedEmailFromRouter, username } = location.state;
      uid = username;
      email = passedEmailFromRouter || username;
    }

    this.state = {
      uid,
      email,
      code,
      chosenResendOption,
      params,
      isLoading: false,
      wasConfirmationCodeRedelivered: false,
    };
    if (!uid) {
      this.state.uidError = "We're sorry, something has gone wrong.";
    }
  }

  onSubmit = async (values) => {
    const { username, verification } = values;
    const { verifyRegistrationConnect, location, checkMobilePromptConnect, verifyLoginConfirmationCodeConnect } = this.props;
    const queryString = location.search;

    this.setState({ isLoading: true });
    if (location.pathname === '/account/confirmLogin') {
      if (location.state && location.state.hstreamId) {
        try {
          const user = {
            hstreamId: location.state.hstreamId,
            verification,
            queryString
          };

          await verifyLoginConfirmationCodeConnect(user);
          const { errorMessage } = this.props;
          const showError = !!errorMessage;
          if (!showError) {
            await checkMobilePromptConnect(queryString);
          }
        } catch (error) {
          console.error("Error during resending confirmation code:", error);
        } finally {
          this.setState({ isLoading: false });
        }
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      verifyRegistrationConnect(username, verification);
      this.setState({ isLoading: false });
    }
  };

  onCancel = () => {
    const { location, history } = this.props;
    const { emails, phoneNumbers, verifyInfo, queryString } = location.state || {};
    history.push({
      pathname: '/account/verifyIdentity',
      search: location.search,
      state: { emails, phoneNumbers, verifyInfo, queryString }
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.completedRegistrationStepTwo) {
      return {
        ...state,
        successfullyConfirmed: true,
      };
    }

    if (
      props.errorMessage &&
      props.errorMessage.includes('Current status is CONFIRMED')
    ) {
      return {
        ...state,
        successfullyConfirmed: true,
      };
    }

    return state;
  }

  resendCode = async () => {
    const { location } = this.props;
    this.setState({ isLoading: true });
    try {
      if (location.pathname === '/account/confirmLogin') {
        const { emails, phoneNumbers, verifyInfo } = location.state || {};
        const { uid } = this.state;
        const hstreamId = verifyInfo.hstreamId;
        const { resendLoginConfirmationCodeConnect } = this.props;
        const selectedEmail = emails.find((email) => email.value === uid);
        const selectedPhone = phoneNumbers.find((phone) => phone.value === uid);
        const email = selectedEmail ? uid : '';
        const phone = selectedPhone ? uid : '';
        const verificationMethod = selectedEmail ? 'email' : 'phone';
        const user = {
          hstreamId,
          email,
          phone,
          verificationMethod,
          queryString: location.search,
        };
        await resendLoginConfirmationCodeConnect(user);
      }
      else {
        const { uid, email } = this.state;
        const {
          resendConfirmationCodeConnect,
          clientId,
          userPoolId,
          reduxUser,
        } = this.props;

        if (!reduxUser) {
          store.dispatch(
            authError({ message: 'Error occurred trying to resend the code.' }),
          );
          return;
        }

        const user = {
          username: uid,
          clientId,
          userPoolId,
          verificationFlow: {
            method: 'email',
            address: email,
            destination: reduxUser.registrationFormValues.email,
          },
        };
        await resendConfirmationCodeConnect(user);
      }
      this.setState({ wasConfirmationCodeRedelivered: true });
    } catch (error) {
      console.error("Error during resending confirmation code:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      errorMessage,
      isRequestPending,
      search,
      location,
      promptForPhone,
    } = this.props;

    const { uid, uidError, code, successfullyConfirmed, email, isLoading, wasConfirmationCodeRedelivered } = this.state;
    const showError = !!errorMessage;
    const hasCode = typeof code !== 'undefined';
    const redirectName = redirect.parseRedirectName(location);
    let isEmailOrPhone = "email";
    let maskedValue = email;

    if (email != null) {
      if (email.indexOf('@') === -1) {
        isEmailOrPhone = "phone number";
        maskedValue = `+1${maskPhoneNumber(email)}`;
      } else {
        maskedValue = maskEmail(email);
      }
    }

    if (promptForPhone) {
      return <PhonePrompt ssoIntegrationOptions={{ originalQueryString: search }} />;
    } else if (promptForPhone === false) {
      return <Redirect push to="/" />;
    }

    return (
      <AuthWrapper
        title={this.props.location.pathname === '/account/confirmLogin' ? 'Code Verification' : 'Verify your hStream ID'}
        redirectName={redirectName}
      >
        {uidError && <Alert color="danger">{uidError}</Alert>}
        {errorMessage && showError && <FormatErrorMessage error={errorMessage} recoverErrorCode={undefined} recoverEmail={undefined} />}
        {!uidError && !successfullyConfirmed && (
          <div>
            {!isRequestPending && !isLoading &&
              !wasConfirmationCodeRedelivered &&
              !location.state.autoVerifyResentCode &&
              !errorMessage && (
                <Alert>A verification code has been sent to {maskedValue}, if the {isEmailOrPhone} exists.</Alert>
              )}
            {!isRequestPending && !isLoading &&
              (wasConfirmationCodeRedelivered ||
                location.state.autoVerifyResentCode) &&
              !errorMessage && (
                <Alert>A verification code has been resent to {maskedValue}, if the {isEmailOrPhone} exists.</Alert>
              )}
            <VerificationCodeForm
              submitOnMount={hasCode}
              onSubmit={this.onSubmit}
              isRequestPending={isRequestPending}
              initialValues={{ username: uid, verification: code }}
              isCancelButtonRequired={this.props.location.pathname === '/account/confirmLogin'}
              onCancel={this.onCancel}
              isLoading={isLoading}
            />
            <div className="AuthWrapper__content-message">
              <hr />
              <ResendCodeSelection resendCode={this.resendCode} email={email} />
              {this.props.location.pathname === '/account/confirmLogin' ? '' :
                <div>
                  <b>Already verified?</b>{' '}
                  {this.state.params && this.state.params.response_type === 'code' && this.state.params.client_id && this.state.params.redirect_uri ? (
                    <a href={`/account/authorize${search}`} target="_self" rel="noopener noreferrer">Sign In</a>
                  ) : (
                    <Link to={{ pathname: '/account/sign-in', search: search, }}>Sign In</Link>
                  )}
                </div>
              }
            </div>
          </div>
        )}
        {!uidError && successfullyConfirmed && (
          <Success toSignIn={{ pathname: '/account/sign-in', search: search }} />
        )}
      </AuthWrapper>
    );
  }
}

Confirm.propTypes = {
  clientId: PropTypes.string.isRequired,
  userPoolId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  search: PropTypes.string.isRequired,
  wasConfirmationCodeRedelivered: PropTypes.bool,
  resendConfirmationCodeConnect: PropTypes.func.isRequired,
  resendLoginConfirmationCodeConnect: PropTypes.func.isRequired,
  verifyRegistrationConnect: PropTypes.func.isRequired,
  checkMobilePromptConnect: PropTypes.func.isRequired,
  verifyLoginConfirmationCodeConnect: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
  promptForPhone: PropTypes.bool,
  completedRegistrationStepTwo: PropTypes.bool,
  reduxUser: PropTypes.shape({
    registrationFormValues: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
};

Confirm.defaultProps = {
  errorMessage: undefined,
  isRequestPending: false,
  completedRegistrationStepTwo: false,
  wasConfirmationCodeRedelivered: false,
  reduxUser: undefined,
  promptForPhone: undefined,
};

const mapStateToProps = (state) => ({
  clientId: selectors.getClientId(state),
  errorMessage: selectors.getErrorMessage(state),
  userPoolId: selectors.getUserPoolId(state),
  search: state.router.location.search,
  isRequestPending: selectors.isRequestPending(state),
  completedRegistrationStepTwo: selectors.completedRegistrationStepTwo(state),
  wasConfirmationCodeRedelivered: state.auth.wasConfirmationCodeRedelivered,
  reduxUser: state.auth.user,
  promptForPhone: selectors.getPromptForPhone(state),
});

const mapDispatchToProps = {
  verifyRegistrationConnect: verifyRegistration,
  resendConfirmationCodeConnect: resendConfirmationCode,
  checkMobilePromptConnect: checkMobilePrompt,
  verifyLoginConfirmationCodeConnect: verifyLoginConfirmationCode,
  resendLoginConfirmationCodeConnect: resendLoginConfirmationCode,
};

const ConnectedConfirm = connect(mapStateToProps, mapDispatchToProps)(Confirm);

export default ConnectedConfirm;
export { Confirm as PureConfirm };
