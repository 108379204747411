import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signOut } from 'redux-modules/auth/actions';
import { Button } from 'reactstrap';

const LogOutButton = ({ signOutConnect, size }) => (
  <Button onClick={signOutConnect} type="button" color="primary" outline size={size}>
    Sign Out
  </Button>
);

LogOutButton.propTypes = {
  signOutConnect: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

LogOutButton.defaultProps = { size: 'sm' };

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  signOutConnect: signOut,
};

const ConnectedLogOutButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogOutButton);

export default ConnectedLogOutButton;
export { LogOutButton as PureLogOutButton };
