import qs from 'qs';
import formatSSOQueryString from './formatSSOQueryString';
import identityClient from '../clients/identityClient';

const getResponseType = (responseTypeRequested) => {
  const validResponseTypes = ['id', 'access', 'token', 'code'];
  return validResponseTypes.includes(responseTypeRequested.toLowerCase())
    ? responseTypeRequested.toLowerCase()
    : undefined;
};

const redirect = {  
  to(location) {
    global.location = location;
  },
  parseRedirectName(location) {
    const { search } = location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { redirect_name: redirectName } = params;
    return redirectName;
  },
  parseRedirectUri(location) {
    const { search } = location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { redirect_uri: redirectUri } = params;
    return redirectUri;
  },
  getSSOIntegrationOptions(location) {
    const { search } = location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    // eslint-disable-next-line camelcase
    const {
      display_remember_me: display,
      remember_me: rememberMe,
      force_sign_in: forceSignIn,
      disable_remember_me: disableRememberMe,
      response_type: responseType,
      state: redirectState,
      suppress_register: suppressRegister,
      client_id: clientId,
      logout_uri: logoutUri,
      firstname: firstname,
      lastname: lastname,
      username: username,
    } = params;
    return {
      rememberMe: {
        display: display === 'true',
        value: rememberMe === 'true' || rememberMe === undefined,
        disable: disableRememberMe === 'true',
      },
      forceSignIn: forceSignIn === 'true',
      responseType: responseType ? getResponseType(responseType) : undefined,
      redirectState,
      suppressRegister: suppressRegister === 'true',
      oauthLogin: responseType && responseType.toLowerCase() === 'code',
      originalQueryString: search,
      clientId,
      logoutUri,
      firstname,
      lastname,
      username,
    };
  },

    async toRedirectUri(redirectUri, clearTokens, responseType, redirectState) {
    const destination = decodeURIComponent(redirectUri); 
  
    await identityClient.getCurrentSession()
      .then(async (resp) => {     
                  const accessToken = resp.data.accessToken;
                  const idToken = resp.data.idToken;
                  const refreshToken = resp.data.refreshToken;         
                  const query = formatSSOQueryString(
                        idToken,
                        accessToken,
                        refreshToken,
                        responseType,
                        redirectState,
                      );

      if (window.location.pathname.indexOf('/sign-out') > -1 || clearTokens) {            
        redirect.to(`${destination}?${query}`);
      }
      else{
        redirect.to(`${destination}?${query}`);
      }
    }).catch((err) => {
      redirect.to(`${destination}`);
      });        
  },

  async userTo(redirectUri) {
    await redirect.toRedirectUri(redirectUri, null, null, null);
  },
};

export default redirect;
