import React from 'react';
// import HstreamLogo from '../../components/HstreamLogo/index';

const AuthWrapperSecondary = () => (
  <div style={{ width: '100px', marginLeft: 'auto', height: '30px' }}>
  
  </div>
);

export default AuthWrapperSecondary;

