import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { signOut } from 'redux-modules/auth/actions';
import LogOutButton from 'components/LogOutButton';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export const idleDuration = 1000 * 60 * 60; // 60 minutes

export const modalCountdownDuration = 1000 * 60; // 1 minute

class InactivityWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsVisible: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.countDown);
  }

  closeModal = () => {
    this.setState({ modalIsVisible: false });
  };

  logout = () => {
    const { signOutConnect } = this.props;
    signOutConnect();
  };

  onActive = () => {
    clearTimeout(this.countDown);
  };

  onIdle = () => {
    this.countDown = setTimeout(this.logout, modalCountdownDuration);
    this.setState({ modalIsVisible: true });
  };

  render() {
    const { modalIsVisible } = this.state;
    return (
      <div>
        <Modal isOpen={modalIsVisible} zIndex={200000}>
          <ModalHeader>Are you still there?</ModalHeader>
          <ModalBody>
            <p>
              You will be automatically logged out in the next minute due to
              inactivity.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModal}>I&apos;m Still Here</Button>
            <LogOutButton size="md" />
          </ModalFooter>
        </Modal>
        <IdleTimer
          timeout={idleDuration}
          onIdle={this.onIdle}
          onActive={this.onActive}
        />
      </div>
    );
  }
}

InactivityWarning.propTypes = {
  signOutConnect: PropTypes.func.isRequired,
};

InactivityWarning.defaultProps = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  signOutConnect: signOut,
};
const ConnectedInactivityWarning = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InactivityWarning);
export default ConnectedInactivityWarning;
export { InactivityWarning as PureInactivityWarning };
