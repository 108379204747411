import qs from 'qs';

function formatSSOQueryString(
  idToken,
  accessToken,
  refreshToken,
  responseType,
  redirectState,
) {
  const sanitizedResponseType = responseType
    && (responseType.toLowerCase() === 'id' ||
      responseType.toLowerCase() === 'access')
    ? responseType.toLowerCase()
    : undefined;

  const query = !sanitizedResponseType
    ? qs.stringify({
      id_token: idToken,
      access_token: accessToken,
      refresh_token: refreshToken,
    })
    : sanitizedResponseType === 'id'
      ? qs.stringify({ id_token: idToken })
      : qs.stringify({ access_token: accessToken });

  return redirectState ? `${query}&state=${redirectState}` : query;
}

export default formatSSOQueryString;
