import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Form, Col, Row, Button } from 'reactstrap';
import EmailField from 'components/fields/EmailField';
import PasswordField from 'components/fields/PasswordField';
import TermsField from 'components/fields/TermsField';
import TextField from 'components/fields/TextField';

const RegisterForm = ({
  handleSubmit,
  onSubmit,
  isRequestPending,
  isPasswordVisible,
  onPasswordToggle,
  PasswordComplexityFeature,
}) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const isContinueDisabled = isRequestPending || (PasswordComplexityFeature && !isPasswordValid);
  const toSignIn = {
    pathname: '/account/sign-in',
    search: window.location.search,
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="6">
          <TextField
            label="First Name"
            name="given_name"
            disabled={isRequestPending}
            required
          />
        </Col>
        <Col sm="6">
          <TextField
            label="Last Name"
            name="family_name"
            disabled={isRequestPending}
            required
          />
        </Col>
      </Row>
      <EmailField
        label="Email"
        name="email"
        disabled={isRequestPending}
      />
      <Row>
        <Col sm="6">
          <PasswordField
            displayPassword={isPasswordVisible}
            onToggle={onPasswordToggle}
            disabled={isRequestPending}
            featureToggle={!PasswordComplexityFeature}
            showTooltip={true}
            setIsPasswordValid={setIsPasswordValid}
          />
        </Col>
        <Col sm="6">
          <PasswordField
            confirm
            displayPassword={isPasswordVisible}
            onToggle={onPasswordToggle}
            disabled={isRequestPending}
            featureToggle={!PasswordComplexityFeature}
            showTooltip={false}
          />
        </Col>
      </Row>
      <TermsField disabled={isRequestPending} />
      <Row>
        <Col sm="4">
          <Button color="primary" block disabled={isContinueDisabled}>
            Continue
          </Button>
        </Col>
        <Col sm="8" className="d-flex align-items-center">
          <div className="AuthWrapper__register-actions">
            Already have an hStream ID?
            <Link to={toSignIn}>Sign In</Link>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPasswordToggle: PropTypes.func.isRequired,
  PasswordComplexityFeature: PropTypes.bool,
  isRequestPending: PropTypes.bool,
  isPasswordVisible: PropTypes.bool,
};

RegisterForm.defaultProps = {
  isRequestPending: false,
  isPasswordVisible: false,
  PasswordComplexityFeature: false,
};

const ConnectedRegisterForm = reduxForm({
  form: 'register',
})(RegisterForm);

export default ConnectedRegisterForm;
export { RegisterForm as PureRegisterForm };
