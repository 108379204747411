import store from 'store';
import axios from 'axios';
import identityClient from '../../clients/identityClient';
import errorMessages from '../../helpers/errorMessages';
import newRelic from '../../helpers/newRelic';
import * as TYPES from './types';
import redirect from '../../helpers/redirect';

export const receivedAwsCredentials = (credentials) => ({
  type: TYPES.RECEIVED_AWS_CREDENTIALS,
  payload: credentials,
});

export const togglePasswordVisibility = () => ({
  type: TYPES.TOGGLE_PASSWORD_VISIBILITY,
  payload: null,
});

export const authRequestBegin = () => ({
  type: TYPES.AUTH_REQUEST_BEGIN,
  payload: null,
});

export const authRequestComplete = () => ({
  type: TYPES.AUTH_REQUEST_COMPLETE,
  payload: null,
});

export const setError = (errorMessage) => ({
  type: TYPES.SET_ERROR,
  payload: errorMessage,
});

export const setPromptForPhone = (value) => ({
  type: TYPES.PROMPT_FOR_PHONE,
  payload: value,
});

export const authRegisterSuccess = (data, userContactFields = {}) => {
  const { email, phone, contact_method } = userContactFields;
  const { user } = data;
  const { pool } = user;
  const { username } = user;
  const { userPoolId } = pool;
  const { clientId } = pool;
  const verificationFlow = {
    method: contact_method,
    address: contact_method === 'email' ? email : phone,
  };
  return {
    type: TYPES.AUTH_REGISTER_SUCCESS,
    payload: {
      username,
      userPoolId,
      clientId,
      verificationFlow,
      registrationFormValues: user.registrationFormValues,
    },
  };
};

export const authError = (data) => ({
  type: TYPES.AUTH_ERROR,
  payload: data.message,
});

export const oauthError = (data) => ({
  type: TYPES.O_AUTH_ERROR,
  payload: data,
});

export const signInError = (data) => ({
  type: TYPES.SIGNIN_ERROR,
  payload: data,
});

export const setCurrentUser = (user) => ({
  type: TYPES.SET_CURRENT_USER,
  payload: user,
});

export const setNoUser = () => ({
  type: TYPES.SET_NO_USER,
  payload: null,
});

export const setUserArrivedSignedIn = () => ({
  type: TYPES.USER_ARRIVED_SIGNED_IN,
  payload: true,
});

export const signUp = (values) => (dispatch) => {
  dispatch(authRequestBegin());
  const state = store.getState();
  const { identityBaseUrl } = state.auth.aws;
  const url = `${identityBaseUrl}/api/accounts/register`;
  const {
    email,
    password,
    given_name: firstName,
    family_name: lastName,
    contact_method: verificationMethod,
    // redirectUri, // Hannah Neal: see comment below :: story 50339
  } = values;
  const registrationForm = {
    email,
    firstName,
    lastName,
    password,
    verificationMethod,
    // redirectUrl: redirectUri, disabling until 3rd party system is ready :: story 50339
  };
  axios
    .post(
      url,
      JSON.stringify(registrationForm),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => {
      dispatch(
        authRegisterSuccess(
          {
            user: {
              username: data.data.accountId,
              pool: {
                clientId: state.auth.aws.clientId,
                userPoolId: state.auth.aws.userPoolId,
              },
              registrationFormValues: {
                email: values.email,
                phone: values.phone,
                firstName: values.given_name,
                lastName: values.family_name,
              },
            },
          },
          {
            email,
            contact_method: verificationMethod,
          },
        ),
      );
    })
    .catch((err) => {
      let errorMessage = 'We are unable to create your hStream ID. If the problem persists, please contact your administrator to ensure all settings are correct.';
      if (err.response.data.status === 409) {
        errorMessage = 'A hStream ID with this email already exists.';
      }
      else if (err.response.data.status === 400 && err.response.data.title === "Password Not Valid") {
        errorMessage = err.response.data.detail;
      }
      newRelic.noticeError(err);
      dispatch(authError({ message: errorMessage }));
    });
};

export const loadingCurrentUser = () => ({
  type: TYPES.LOADING_CURRENT_USER,
  payload: null,
});

export const loadedCurrentUser = () => ({
  type: TYPES.LOADED_CURRENT_USER,
  payload: null,
});

export const bypassImplicitUserCheck = () => (dispatch) => {
  dispatch(loadedCurrentUser());
}

export const checkCurrentUser = (setUserArrivedSignedInFlag) => async (dispatch) => {
  dispatch(loadingCurrentUser());
  //check CSRF token
  await identityClient.getCurrentSession()
    .then(async (resp) => {
      const user = {
        "hstreamid": resp.data.hstreamId,
        "given_name": resp.data.givenName,
        "family_name": resp.data.familyName,
        "attributes": [{
          "email": resp.data.email,
          "email_verified": resp.data.emailVerified,
          "phone_number": resp.data.phoneNumber,
          "phone_number_verified": resp.data.phoneNumberVerified
        }],
        "pool": [{
          "email": resp.data.email,
          "email_verified": resp.data.emailVerified,
          "phone_number": resp.data.phoneNumber,
          "phone_number_verified": resp.data.phoneNumberVerified
        }]
      }
      dispatch(loadedCurrentUser());
      if (resp.data.sessionId) {
        dispatch(setCurrentUser(user));
      }
      if (setUserArrivedSignedInFlag) {
        dispatch(setUserArrivedSignedIn());
      }
    }).catch((err) => {
      newRelic.setErrorHandler(() => err === 'not authenticated');
      newRelic.noticeError(err);
      dispatch(loadedCurrentUser());
    });
};

export const authCodeResentSuccess = () => ({
  type: TYPES.AUTH_CODE_RESENT,
  payload: null,
});

export const resendConfirmationCode = (userData) => (dispatch) => {
  dispatch(authRequestBegin());
  const state = store.getState();
  const { verificationFlow } = userData; // username
  const { identityBaseUrl } = state.auth.aws;
  const url = `${identityBaseUrl}/api/accounts/resendVerification`;
  const payload = {
    message: {
      hstreamId: userData.username,
      email:
        verificationFlow.destination.toLowerCase()
    },
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((result) => {
      if (!result.ok) {
        dispatch(authError(errorMessages.resendCode));
      } else {
        dispatch(authCodeResentSuccess());
      }
    })
    .catch((err) => {
      newRelic.noticeError(err);
      dispatch(authError(errorMessages.resendCode));
    })
    .finally(() => {
      dispatch(authRequestComplete());
    });
};

export const resendLoginConfirmationCode = (userData) => async (dispatch) => {
  dispatch(authRequestBegin());
  const { hstreamId, email, phone, verificationMethod, queryString } = userData;
  await identityClient.sendOtp(hstreamId, email, phone, verificationMethod, queryString)
    .then((result) => {
      dispatch(authCodeResentSuccess());
    })
    .catch((err) => {
      let errorMessage = errorMessages.resendCode.message;
      if (err.response.data.status === 400 && err.response.data.title === "No Active Session") {
        errorMessage = errorMessages.noActiveSession.message;
      }
      newRelic.noticeError(err);
      dispatch(authError({ message: errorMessage }));
    })
    .finally(() => {
      dispatch(authRequestComplete());
    });
};

export const verifyLoginConfirmationCode = (userData) => async (dispatch) => {
  dispatch(authRequestBegin());
  const { hstreamId, verification, queryString } = userData;
  await identityClient.confirmOtp(hstreamId, verification, queryString)
    .then((result) => {
      dispatch(authCodeResentSuccess());
    })
    .catch((err) => {
      let errorMessage = errorMessages.generic.message;
      if (err.response && err.response.data && err.response.data.status === 400 && err.response.data.title === "No Active Session") {
        errorMessage = errorMessages.noActiveSession.message;
      }
      else if (err.response && err.response.data) {
        errorMessage = err.response.data.detail;
      }
      newRelic.noticeError(err);
      dispatch(authError({ message: errorMessage }));
    })
    .finally(() => {
      dispatch(authRequestComplete());
    });
};
export const checkMobilePrompt = (queryString) => async (dispatch) => {
  const state = store.getState();
  const { featureMobilePrompt } = state.auth.aws;
  let showPhonePromptRequired = false;
  try {
    if (featureMobilePrompt == 1) {
      const myAccountData = await identityClient.myAccount(queryString);
      if (!myAccountData.phoneNumbers.find((p) => p.verified === true)) {
        const prefs = await identityClient.getAccountPreferences(queryString);
        const phonePromptKey = 'hstreamid_phonepromptdate';
        const phonePrompt = prefs
          ? prefs.find((x) => x.key === phonePromptKey)
          : null;
        const phonePromptDate = phonePrompt
          ? new Date(phonePrompt.value)
          : null;
        const today = new Date();
        if (phonePrompt && today.getTime() > phonePromptDate.getTime()) {
          showPhonePromptRequired = true;
        }
        // if there is no preference we treat this as a first time sign in, which we do not
        // show the phone prompt for. So we set a preference for a date in the past to ensure
        // the user is shown it on the next sign in.
        if (!phonePrompt) {
          // Setting showPhonePromptRequired = true so we will get a
          // phonePrompt on initial login
          const newPhonePromptDate = new Date();
          showPhonePromptRequired = true;
          newPhonePromptDate.setDate(newPhonePromptDate.getDate());
          await identityClient.setAccountPreference(
            phonePromptKey,
            newPhonePromptDate.toISOString(),
            queryString
          );
        }
      }
    }
  } catch (err) {
    newRelic.noticeError(err);
  }
  if (showPhonePromptRequired == false && localStorage.getItem('isMobilePrompt') == 1) {
    localStorage.setItem('isMobilePrompt', 0);
    redirect.to(`${localStorage.getItem('oAuthRedirectUrl')}`);
  } else {
    dispatch(setPromptForPhone(showPhonePromptRequired));
    dispatch(checkCurrentUser());
  }
};

export const signOut = (response_type, client_id) => async (dispatch) => {
  localStorage.clear();
  dispatch(setNoUser());
  await identityClient.logOut(response_type, client_id)
    .then(() => {
      dispatch(setNoUser());
      dispatch(loadedCurrentUser());
    })
    .catch((err) => {
      newRelic.noticeError(err);
    });
};

export const oAuthSignOutAndRedirect = (redirectUri, client_id, responseType, redirectState) => async (dispatch) => {
  localStorage.clear();
  dispatch(setNoUser());
  await identityClient.oAuthClientSignOut(redirectUri, client_id)
    .then((url) => {
      redirectUri = url.data;
    })
  dispatch(setNoUser());
  if (redirectUri) {
    redirect.toRedirectUri(redirectUri, true, responseType, redirectState);
  } else {
    redirect.toRedirectUri('sign-in', true, responseType, redirectState);
  }
};

export const signOutAndRedirect = (redirectUri, responseType, redirectState, client_id) => async (dispatch) => {
  localStorage.clear();
  dispatch(setNoUser());
  await identityClient.logOut(responseType, client_id)
  dispatch(setNoUser());
  if (redirectUri)
    redirect.toRedirectUri(redirectUri, true, responseType, redirectState)
  else
    redirect.toRedirectUri('sign-in', true, responseType, redirectState)
};

export const togglePhonePrompt = (value) => (dispatch) => {
  dispatch(setPromptForPhone(value));
};

export const forgotPasswordSent = (username) => ({
  type: TYPES.FORGOT_PASSWORD_SENT,
  payload: username,
});

export const forgotPasswordSubmitSuccess = () => ({
  type: TYPES.FORGOT_PASSWORD_SUBMIT_SUCCESS,
  payload: null,
});

export const forceResetPasswordSubmitSuccess = () => ({
  type: TYPES.FORCE_RESET_PASSWORD_SUBMIT_SUCCESS,
  payload: null,
});

export const forgotPasswordSubmit = (hstreamId, code, password) => (
  dispatch,
) => {
  dispatch(authRequestBegin());

  const state = store.getState();
  const { identityBaseUrl } = state.auth.aws;
  axios
    .post(
      `${identityBaseUrl}/api/accounts/setpassword`,
      {
        hstreamId,
        password,
        VerificationCode: code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(() => {
      dispatch(authRequestComplete());
      dispatch(forgotPasswordSubmitSuccess());
    })

    .catch((err) => {
      var errorMessage = null;
      if (err.response.data.status === 400 && err.response.data.title === "Password Not Valid") {
        errorMessage = err.response.data.detail;
      }
      newRelic.noticeError(err);
      dispatch(authError(errorMessage === null ? errorMessages.expiredVerificationLinkError : { message: errorMessage }));
    });
};

export const forceResetPasswordSubmit = (telecom, currentPassword, password) => (
  dispatch,
) => {
  dispatch(authRequestBegin());

  const state = store.getState();
  const { identityBaseUrl } = state.auth.aws;
  telecom = decodeURIComponent(telecom);
  axios
    .post(
      `${identityBaseUrl}/api/forceResetPassword`,
      {
        telecom,
        currentPassword,
        password,
      },

      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(() => {
      dispatch(authRequestComplete());
      dispatch(forceResetPasswordSubmitSuccess());
    })
    .catch((err) => {
      var errorMessage = null;
      if (err.response.data.status === 400 && err.response.data.title === "Password Not Valid") {
        errorMessage = err.response.data.detail;
      }
      newRelic.noticeError(err);
      dispatch(authError(errorMessage === null ? errorMessages.generic : { message: errorMessage }));
    });
};


export const forgotPassword = (telecom, params) => async (dispatch) => {
  dispatch(authRequestBegin());

  const state = store.getState();
  const { identityBaseUrl } = state.auth.aws;
  axios
    .post(
      `${identityBaseUrl}/api/accounts/initiateresetpassword`,
      {
        telecom,
        params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(() => {
      dispatch(authRequestComplete());
      dispatch(forgotPasswordSent(telecom));
    })
    .catch((err) => {
      newRelic.noticeError(err);
      dispatch(authError(errorMessages.generic));
    });
};

export const redoForgotPassword = () => ({
  type: TYPES.REDO_FORGOT_PASSWORD,
  payload: null,
});

export const confirmUserIdSuccess = () => ({
  type: TYPES.CONFIRM_USER_ID_SUCCESS,
  payload: null,
});

export const registrationSuccess = () => ({
  type: TYPES.REGISTRATION_SUCCESS,
  payload: null,
});

export const verifyRegistration = (username, verification) => (dispatch) => {
  dispatch(authRequestBegin());
  const state = store.getState();
  const { identityBaseUrl } = state.auth.aws;
  const url = `${identityBaseUrl}/api/accounts/verify`;

  const payload = {
    hstreamId: username,
    code: verification,
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        const errorMessage = {
          default: 'An error occurred trying to verify your hStream ID.',
        };

        response.json().then((data) =>
          dispatch(
            authError({
              message: data.detail || errorMessage.default,
            }),
          ),
        );
      } else {
        dispatch(registrationSuccess());
      }
    })
    .catch((err) => {
      newRelic.noticeError(err);
      dispatch(authError(errorMessages.processing));
    })
    .finally(() => dispatch(authRequestComplete()));
};
