import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';

const TooltipStyles = {
  c: {
    maxWidth: '400px',
  },
  ul: {
    textAlign: 'left',
    padding: 0,
    margin: 0,
  },
  li: {
    margin: '.5em .5em .5em 1.5em',
    padding: '0',
  },
};

const LabelWithLoginInformationTooltip = ({ labelText, tooltipId, tooltipContent }) => {
  const [displayTooltip, toggleToolTip] = useState(false);

  const handleToggle = () => {
    setTimeout(() => {
      toggleToolTip(!displayTooltip);
    }, 200); 
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {labelText}
      {' '}
      <FontAwesomeIcon
        className="hpaTooltip-trigger"
        style={{ position: 'relative', color: '#5bc0de' }}
        icon={faInfoCircle}
        id={tooltipId}
      />
      <Tooltip placement="top" isOpen={displayTooltip} target={tooltipId} toggle={handleToggle} style={TooltipStyles.c}>
        <ul style={TooltipStyles.ul}>
          {tooltipContent.map((t) => <li key={t} style={TooltipStyles.li}>{t}</li>)}
        </ul>
      </Tooltip>
    </div>
  );
};

LabelWithLoginInformationTooltip.propTypes = {
  labelText: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
  tooltipContent: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LabelWithLoginInformationTooltip;
