const switchTransition = {
  atEnter: {
    opacity: 0,
  },
  atLeave: {
    opacity: 0,
  },
  atActive: {
    opacity: 1,
  },
};

export default switchTransition;
