import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ResendCodeSelection = ({ resendCode, email }) => {
  let isEmailOrPhone = "email";
  if (email != null)
    isEmailOrPhone = (email.indexOf('@') === -1) ? "Phone" : "Email";
  return (
    <div className="d-flex justify-content-start align-items-center flex-wrap">
      <b style={{ marginRight: '4px' }}>Need a new code?</b>
      <div className="d-flex justify-content-start align-items-center">
        <span>Resend to your</span>
        <Button
          color="link"
          size="sm"
          onClick={() => resendCode()}
          style={{ padding: 0, margin: '0 4px', fontSize: '1em' }}
          id={isEmailOrPhone}
        >
          {isEmailOrPhone}
        </Button>
      </div>
    </div>
  );
};

ResendCodeSelection.propTypes = {
  resendCode: PropTypes.func.isRequired,
};

export default ResendCodeSelection;
