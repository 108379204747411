/* eslint-disable import/prefer-default-export */
export const blacklist = [
  'password',
  'qwerty',
  'abc123',
  '12345678',
  'password1',
  '12345',
  'iloveyou',
  '1q2w3e4r5t',
  'qwertyuiop',
  'monkey',
  '123321',
  'dragon',
  '654321',
  '123',
  'myspace1',
  'a123456',
  '121212',
  '1qaz2wsx',
  '123qwe',
  '123abc',
  'tinkle',
  'target123',
  'gwerty',
  '1g2w3e4r',
  'gwerty123',
  'zag12wsx',
  'qwerty1',
  '1q2w3e4r',
  '987654321',
  '222222',
  'qwe123',
  'qwerty123',
  'zxcvbnm',
  '555555',
  '112233',
  'fuckyou',
  'asdfghjkl',
  '12345a',
  '123123123',
  '1q2w3e',
  'qazwsx',
  'computer',
  'aaaaaa',
  '159753',
  'iloveyou1',
  'fuckyou1',
  'princess',
  '789456123',
  '123654',
  'princess1',
  'linkedin',
  'michael',
  'sunshine',
  'football',
  '1234qwer',
  '999999',
  'j38ifUbn',
  'monkey1',
  'football1',
  'daniel',
  'azerty',
  'a12345',
  '123456789a',
  '789456',
  'asdfgh',
  'love123',
  'abcd1234',
  'jordan23',
  '88888888',
  '5201314',
  '12qwaszx',
  'FQRG7CS493',
  'ashley',
  'asdf',
  'asd123',
  'superman',
  'jessica',
  'love',
  'samsung',
  'shadow',
  'blink182',
  '333333',
  'michael1',
  'babygirl1',
  'jesus1',
  'qwert',
  'k.:',
  'baseball',
  'charlie',
  '0',
  'hello1',
  'soccer',
  'killer',
  '131313',
  'master',
  '1111111',
  'gfhjkm',
  '0123456789',
  '987654',
  'iloveyou2',
  'angel1',
  'jordan',
  '147258369',
  'bitch1',
  'michelle',
  'q1w2e3r4',
  'jessica1',
  'qwer1234',
  '159357',
  'soccer1',
  'liverpool',
  '101010',
  'zxcvbn',
  'thomas',
  'asdasd',
  'fuckyou2',
  'justin',
  'nicole',
  'qazwsxedc',
  'baseball1',
  'andrew',
  'hello',
  'apple',
  '0987654321',
  'anthony1',
  '102030',
  'money1',
  'parola',
  'abc',
  '147258',
  'anthony',
  '111222',
  'jennifer',
  'number1',
  'naruto',
  '123456q',
  '696969',
  'joshua',
  'golfer',
  '29rsavoy',
  'myspace',
  'andrea',
  'basketball',
  'qwerty12',
  'charlie1',
  'passw0rd',
  'asshole1',
  'hunter',
  'marina',
  'welcome',
  '010203',
  'superman1',
  'password12',
  'xbox360',
  'sunshine1',
  'ashley1',
  'lovely',
  'babygirl',
  '!',
  'trustno1',
  '666',
  'asdf1234',
  'chocolate',
  'buster',
  'summer',
  'tigger',
  'purple',
  'freedom',
  'loveme',
  'matthew',
  '50cent',
  'password2',
  'maggie',
  'george',
  'chelsea',
  '12341234',
  'amanda',
  'hannah',
  'q1w2e3',
  'friends',
  'shadow1',
  'william',
  'abcdefg',
  'samantha',
  '12344321',
  'nicole1',
  'q1w2e3r4t5y6',
  'robert',
  'mother',
  'jordan1',
  'secret',
  'letmein',
  'qweasdzxc',
  '212121',
  'pokemon',
  '$HEX',
  'internet',
  'batman',
  'love12',
  'a123456789',
  'VQsaBLPzLa',
  'qweqwe',
  'hello123',
  '232323',
  'butterfly',
  'martin',
  'flower',
  'forever',
  'mustang',
  '1qazxsw2',
  'iloveu',
  'cjmasterinf',
  'orange',
  'harley',
  'user',
  'brandon1',
  'london',
  '1234567891',
  'pepper',
  'chris1',
  'lol123',
  'abcdef',
  'whatever',
  '1342',
  'alexander',
  'loveyou',
  '290966',
  'wall.e',
  'junior',
  '12413',
  'qweasd',
  'PE#5GZ29PTZMSE',
  'tudelft',
  'dpbk1234',
  'DIOSESFIEL',
  'U38fa39',
  '147852',
  'cookie',
  'family',
  'jasmine',
  'dragon1',
  '12345q',
  'nikita',
  'pakistan',
  '123654789',
  '123789',
  'amanda1',
  'joseph',
  'happy1',
  'ginger',
  'matthew1',
  'snoopy',
  'justin1',
  'lastfm',
  '3rJs1la7qE',
  'antonio',
  'barcelona',
  'matrix',
  'computer1',
  'hottie1',
  'sophie',
  'sandra',
  'michelle1',
  '12345678910',
  'qqqqqq',
  'arsenal',
  '444444',
  'brandon',
  'daniel1',
  'jonathan',
  'killer1',
  'liverpool1',
  'mickey',
  'ghbdtn',
  'purple1',
  'mercedes',
  'patrick',
  '11223344',
  'diamond',
  '456789',
  'victoria',
  'asshole',
  'taylor',
  'qwertyu',
  'andrew1',
  'red123',
  'lucky1',
  'eminem',
  '12345qwert',
  '111222tianya',
  'yellow',
  'william1',
  'bailey',
  'angel',
  'chicken1',
  'richard',
  '0000',
  'banana',
  'jasmine1',
  'benjamin',
  'welcome1',
  'starwars',
  'hunter1',
  'cheese',
  'melissa',
  'angela',
  'christian',
  '1234554321',
  'oliver',
  'chocolate1',
  'butterfly1',
  'peanut',
  '55555',
  'hockey',
  'mylove',
  'natasha',
  'NULL',
  'mommy1',
  '1234561',
  'q1w2e3r4t5',
  'america',
  '252525',
  'monster',
  'school',
  '456123',
  'james1',
  'slipknot',
  'hannah1',
  'zaq12wsx',
  'chicken',
  '147852369',
  'gabriel',
  'elizabeth',
  'cookie1',
  'Status',
  '87654321',
  'robert1',
  'ferrari',
  'nathan',
  '1password',
  'buddy1',
  '1314520',
  'america1',
  'metallica',
  'chelsea1',
  'zzzzzz',
  'prince',
  'adidas',
  'jackson',
  'morgan',
  'rainbow',
  'silver',
  '1234567a',
  'angels',
  'iw14Fi9j',
  'loveme1',
  'juventus',
  'jennifer1',
  '!~!1',
  'bubbles',
  'samuel',
  'fuckoff',
  'lovers',
  'cheese1',
  '0123456',
  '123asd',
  'madison',
  'elizabeth1',
  'music',
  'buster1',
  'lauren',
  'david1',
  'tigger1',
  '123qweasd',
  'taylor1',
  'carlos',
  'tinkerbell',
  'samantha1',
  'joshua1',
  'poop',
  'stella',
  'myspace123',
  'asdasd5',
  'freedom1',
  'whatever1',
  'xxxxxx',
  '00000',
  'valentina',
  'a1b2c3',
  '741852963',
  'austin',
  'monica',
  'qaz123',
  'lovely1',
  'music1',
  'harley1',
  'family1',
  'spongebob1',
  'steven',
  'nirvana',
  '1234abcd',
  'hellokitty',
  'thomas1',
  '7654321',
  'madison1',
  'daddy1',
  'summer1',
  'cocacola',
  'nicholas',
  'zxc123',
  '123456m',
  'qwertyui',
  'spiderman',
  'vanessa',
  'diamond1',
  '142536',
  'danielle',
  'badoo',
  '7758521',
  'bandit',
  'pokemon1',
  'mustang1',
  '1qaz2wsx3edc',
  'alexis',
  'loulou',
  'justinbieb',
  'yamaha',
  'qwert1',
  'scooter',
  'rachel',
  'tennis',
  'ronaldo',
  'i',
  'mexico1',
  'friends1',
  'victor',
  'maggie1',
  'asdfasdf',
  'qwerty12345',
  'lover1',
  'jesus',
  '123hfjdk147',
  'nicolas',
  'batman1',
  'weed420',
  'password123',
  'loser1',
  '123456j',
  'iloveyou!',
  'pepper1',
  'fuckoff1',
  '555666',
  'iloveu2',
  'sabrina',
  'pussy1',
  'bubbles1',
  '098765',
  'master1',
  'smokey',
  'a1b2c3d4',
  '123456789q',
  'qwaszx',
  'heather',
  'jasper',
  'booboo',
  'heather1',
  '4815162342',
  'peanut1',
  'chester',
  '123456s',
  '123456b',
  'google',
  'edward',
  'yankees1',
  'canada',
  'Exigent',
  'destiny',
  'success',
  'nigger1',
  '135790',
  'asdfghjkl1',
  '124578',
  'casper',
  'lalala',
  'mother1',
  'sexy123',
  'qazxsw',
  'naruto1',
  '1q2w3e4r5t6y',
  'david',
  'money',
  'yellow1',
  'patrick1',
  'flower1',
  '12121212',
  'alexander1',
  'raiders1',
  'Password1',
  'sebastian',
  '134679',
  'zxcvbnm1',
  'dennis',
  '852456',
  'hahaha',
  'daniela',
  'ginger1',
  'olivia',
  'melissa1',
  '010101',
  'slipknot1',
  'spiderman1',
  'cowboys1',
  '0000000',
  'rebecca',
  '741852',
  'jeremy',
  'a1234567',
  'dakota',
  '123456d',
  '1a2b3c',
  'apple1',
  'november',
  'alexandra',
  '159951',
  'iloveu1',
  'veronica',
  'fuckme1',
  'baby123',
  'yankees',
  'stupid1',
  'cristina',
  'newyork1',
  'jackson1',
  'playboy',
  'friend',
  'iloveyou12',
  'sammy1',
  'pimpin1',
  'phoenix',
  'rocky1',
  'password!',
  'joseph1',
  '753951',
  'p',
  'a838hfiD',
  'richard1',
  'beautiful1',
  'mickey1',
  'carolina',
  'j123456',
  '202020',
  'newyork',
  'patricia',
  'charles',
  'stephanie',
  'orange1',
  'm123456',
  '421uiopy258',
  'myspace2',
  'cameron',
  'spider',
  'barbie',
  'woaini',
  'vincent',
  'mexico',
  'scorpion',
  'monster1',
  'aaaaa',
  'elephant',
  'asdf123',
  '963852741',
  'zk.:',
  'guitar',
  'fucker1',
  'destiny1',
  'hotmail',
  'johnny',
  'doudou',
  'q123456',
  'bailey1',
  'asdfgh1',
  'fucker',
  'louise',
  'sparky',
  'sweety',
  '123456abc',
  'shorty1',
  'booboo1',
  'december',
  '9876543210',
  'manchester',
  'midnight',
  '246810',
  'jessie',
  'dallas',
  'austin1',
  's123456',
  'pass',
  '12345678a',
  'claudia',
  'kristina',
  'lakers',
  'lovelove',
  'crazy1',
  'tiger1',
  'thunder',
  'dolphin',
  'a',
  'gangsta1',
  'jackie',
  '151515',
  'charlotte',
  'scooter1',
  'caroline',
  'fuck',
  'merlin',
  'junior1',
  'super123',
  'scooby',
  'marseille',
  'aaaa',
  'metallica1',
  'kitty1',
  'chris',
  'beautiful',
  'black1',
  'danielle1',
  'blessed1',
  'skater1',
  '1029384756',
  'qazwsx123',
  '456456',
  'b123456',
  'genius',
  'guitar1',
  'tyler1',
  'peaches',
  'california',
  'sakura',
  'tigers',
  'soleil',
  'lauren1',
  'green1',
  'smokey1',
  'cooper',
  '520520',
  'muffin',
  'christian1',
  'love13',
  'fucku2',
  'arsenal1',
  'lucky7',
  'diablo',
  'apples',
  'george1',
  'babyboy1',
  'crystal',
  '1122334455',
  'player1',
  'aa123456',
  'vfhbyf',
  'forever1',
  'Password',
  'winston',
  'chivas1',
  'sexy',
  'hockey1',
  '1a2b3c4d',
  'pussy',
  'playboy1',
  'stalker',
  'cherry',
  'tweety',
  'toyota',
  'creative',
  'gemini',
  'pretty1',
  'maverick',
  'brittany1',
  'nathan1',
  'letmein1',
  'cameron1',
  'secret1',
  'google1',
  'heaven',
  'martina',
  'murphy',
  'spongebob',
  'uQA9Ebw445',
  'fernando',
  'pretty',
  'startfinding',
  'softball',
  'dolphin1',
  'fuckme',
  'test123',
  'qwerty1234',
  'kobe24',
  'alejandro',
  'adrian',
  'september',
  'aaaaaa1',
  'bubba1',
  'isabella',
  'abc123456',
  'password3',
  'jason1',
  'abcdefg123',
  'loveyou1',
  'shannon',
  '100200',
  'manuel',
  'leonardo',
  'molly1',
  'flowers',
  '123456z',
  '007007',
  'password.',
  '321321',
  'miguel',
  'samsung1',
  'sergey',
  'sweet1',
  'abc1234',
  'windows',
  'qwert123',
  'vfrcbv',
  'poohbear',
  'd123456',
  'school1',
  'badboy',
  '951753',
  '123456c',
  '111',
  'steven1',
  'snoopy1',
  'garfield',
  'YAgjecc826',
  'compaq',
  'candy1',
  'sarah1',
  'qwerty123456',
  '123456l',
  'eminem1',
  '141414',
  '789789',
  'maria',
  'steelers',
  'iloveme1',
  'morgan1',
  'winner',
  'boomer',
  'lolita',
  'nastya',
  'alexis1',
  'carmen',
  'angelo',
  'nicholas1',
  'portugal',
  'precious',
  'jackass1',
  'jonathan1',
  'yfnfif',
  'bitch',
  'tiffany',
  'rabbit',
  'rainbow1',
  'angel123',
  'popcorn',
  'barbara',
  'brandy',
  'fuckyou!',
  'starwars1',
  'barney',
  'natalia',
  'hiphop',
  'tiffany1',
  'shorty',
  'poohbear1',
  'simone',
  'albert',
  'marlboro',
  'hardcore',
  'cowboys',
  'sydney',
  'alex',
  'scorpio',
  '1234512345',
  'q12345',
  'qq123456',
  'onelove',
  'bond007',
  'abcdefg1',
  'eagles',
  'crystal1',
  'azertyuiop',
  'winter',
  'sexy12',
  'angelina',
  'james',
  'svetlana',
  'fatima',
  '123456k',
  'icecream',
  'popcorn1',
  '121314',
  'john316',
  'qazwsx1',
  'victoria1',
  'twilight',
  'iloveme',
  '9379992',
  'pass123',
  'dancer',
  'brittany',
  'beauty',
  'bonjour',
  'maxwell',
  'coffee',
  'dexter',
  '454545',
  'qazqaz',
  'snickers',
  'love11',
  'samson',
  'aaaaaaaa',
  'swordfish',
  'fyfcnfcbz',
  'abcd123',
  'aaa111',
  'natalie',
  'hottie',
  'passion',
  'alyssa',
  'rockstar1',
  'lovers1',
  'florida',
  'alicia',
  'happy',
  'blue123',
  '123456t',
  'ranger',
  'yourmom1',
  'pumpkin',
  'denise',
  'edward1',
  'tweety1',
  'christine',
  'august',
  '54321',
  'bella1',
  'marie1',
  'seven7',
  'steelers1',
  'aaaaa1',
  'shannon1',
  'amber1',
  'cutie1',
  'peaches1',
  'florida1',
  'bonnie',
  'stephanie1',
  'lollipop',
  'cassie',
  'k.',
  'rachel1',
  'greenday1',
  'krishna',
  'teresa',
  'october',
  'iverson3',
  'motorola',
  'rockstar',
  'hahaha1',
  'police',
  'lakers24',
  'fylhtq',
  'andrey',
  'loveme2',
  'turtle',
  'southside1',
  'baby',
  'bismillah',
  'pa55word',
  'blessed',
  'emmanuel',
  '666999',
  '012345',
  'fluffy',
  '5555555555',
  'stupid',
  'karina',
  'fishing',
  'musica',
  'password11',
  'love4ever',
  'melanie',
  'greenday',
  'isabelle',
  'nothing',
  'abcd',
  'chicago',
  'cowboy',
  'mnbvcxz',
  'andrea1',
  '242424',
  'babygurl1',
  'santiago',
  'ssssss',
  'kevin1',
  'lakers1',
  'chester1',
  '321654',
  'kimberly',
  'carlos1',
  'z123456',
  'daisy1',
  'jackass',
  'm',
  'zoosk',
  'boston',
  'happy123',
  'satan666',
  'pamela',
  '090909',
  'francesco',
  'horses',
  '456852',
  'qwer',
  'vanessa1',
  'redsox',
  'pookie',
  'a12345678',
  '110110',
  'tucker',
  'marley',
  'corvette',
  '778899',
  'realmadrid',
  'raiders',
  'rangers',
  'people',
  '1123581321',
  'soccer12',
  'sayang',
  'shelby',
  'christ',
  '12345t',
  'fktrcfylh',
  'kitten',
  'player',
  'c123456',
  'qwert12345',
  'baby12',
  'trinity',
  '1v7Upjw3nT',
  'p@ssw0rd',
  'thunder1',
  'zxcvbnm123',
  'midnight1',
  'lebron23',
  'golden',
  'strawberry',
  'orlando',
  'love1234',
  'lucky13',
  'asdfg1',
  'marine',
  'soccer10',
];
