import React from 'react';
import PropTypes from 'prop-types';
import { Input as RSInput } from 'reactstrap';

import HStreamIDBaseInput from './HStreamIDBaseInput';

const inputTypes = `email
password
url
number
datetime
date
time
color
search
tel
text
file`.split('\n');

const HStreamIDInput = ({
  errorMessage,
  successMessage,
  helpText,
  hideLabel,
  id,
  onChange,
  label,
  type,
  ...domAttributes
}) => (
  <HStreamIDBaseInput {
    ...{
      errorMessage,
      successMessage,
      helpText,
      hideLabel,
      id,
      onChange,
      label,
    }}
  >
    <RSInput type={type} {...domAttributes} />
  </HStreamIDBaseInput>
);

HStreamIDInput.propTypes = {
  ...HStreamIDBaseInput.childPropTypes,
  type: PropTypes.oneOf(inputTypes),
};

HStreamIDInput.defaultProps = {
  ...HStreamIDBaseInput.defaultChildProps,
  type: 'text',
};

export default HStreamIDInput;
export { inputTypes };
