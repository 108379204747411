import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'reactstrap';
import PhoneNumberField from 'components/fields/PhoneNumberField';

const PhonePromptForm = ({ handleSubmit, onSubmit, isRequestPending }) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <PhoneNumberField
        label="Mobile Number"
        name="phone"
        disabled={isRequestPending}
        required
      />
      <PhoneNumberField
        label="Confirm Mobile Number"
        name="confirm-phone"
        disabled={isRequestPending}
        required
        confirm
      />
      <FormGroup>
        <Button color="primary" block disabled={isRequestPending}>
          Send Verification Code
        </Button>
      </FormGroup>
    </Form>
  );
};

PhonePromptForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPasswordToggle: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
  isPasswordVisible: PropTypes.bool,
};

PhonePromptForm.defaultProps = {
  isRequestPending: false,
  isPasswordVisible: false,
};

const ConnectedPhonePromptForm = reduxForm({
  form: 'phone-prompt',
})(PhonePromptForm);

export default ConnectedPhonePromptForm;
export { PhonePromptForm as PurePhonePromptForm };
