import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../../core/Input';
import { isEmail, required } from './validate';

const EmailFieldComponent = (props) => {
  const {
    input, meta, disabled, label, helpText,
  } = props;
  const { touched, error } = meta;
  const errorMessage = touched && error ? error : '';
  return (
    <Input
      id="email"
      type="email"
      label={label}
      helpText={helpText}
      disabled={disabled}
      errorMessage={errorMessage}
      {...input}
    />
  );
};

const EmailField = ({
  name,
  label,
  disabled,
  helpText,
}) => (
  <Field
    name={name}
    component={EmailFieldComponent}
    disabled={disabled}
    label={label}
    validate={[required, isEmail]}
    helpText={helpText}
  />
);

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
};

EmailField.defaultProps = {
  disabled: false,
  label: 'Email Address',
  helpText: undefined,
};

export default EmailField;
export { EmailFieldComponent };
