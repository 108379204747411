/**
 * Masks the local part of the email address.
 * @param {string} email - The email address to mask.
 * @returns {string} The masked email address.
 */

export const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
    return `${maskedLocalPart}@${domain}`;
};

/**
 * Masks the phone number.
 * @param {string} phoneNumber - The phone number to mask.
 * @returns {string} The masked phone number.
 */

export const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\+1)(\d{3})(\d{3})(\d{4})/, 'XXX-XXX-$4');
};
