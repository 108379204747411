import axios from 'axios';
import qs from 'qs';
import newRelic from '../helpers/newRelic';

const identityClientConfig = {
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
};

const identityAuthCodeFlowConfig = {
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
};

const identityClient = {
  async setAccountPreference(preference, value, queryString) {
    try {
      await axios.post(
        `/api/auth/setaccountpreference${queryString}`,
        { preference, value },
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  async addPhoneNumber(phone, queryString) {
    try {
      await axios.post(
        `/api/auth/addphonenumber${queryString}`,
        { phone },
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
      return;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  async sendVerificationCode(phone, email, verificationMethod) {
    try {
      const resp = await this.getCurrentSession();
      identityClientConfig.headers['Authorization'] = resp.data.accessToken;
      await axios.post(
        '/api/accounts/sendverification',
        {
          message: {
            phone,
            verificationMethod,
            email,
            hstreamId: resp.data.hstreamId,
          },
        },
        identityClientConfig,
      )
      return;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },


  async verifyPhone(username, verification) {
    const payload = {
      hstreamId: username,
      code: verification,
    };
    try {
      await axios.post(
        `/api/accounts/verify`,
        JSON.stringify(payload),
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
      return;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  async myAccount(queryString) {
    try {
      const response = await axios.get(
        `/api/auth/getAccount${queryString}`,
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
      return response.data;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },
  async getAccountInfo(hstreamId) {
    try {
      const response = await axios.get(
        `/api/${hstreamId}/getAccount`,
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
      return response.data;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },
  async getAccountPreferences(queryString) {
    try {
      const response = await axios.get(
        `/api/auth/getaccountpreferences${queryString}`,
        { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
      )
      return response.data.preferences;
    }
    catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  async oauthGetLogin(queryString) {
    const response = await axios.get(`/api/auth/login${queryString}`, { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } });
    identityAuthCodeFlowConfig.headers['x-xsrf-token'] = response.headers['x-xsrf-token'];
    return response;
  },

  async blindLogin(queryString) {
    try {
      const resp = await axios.post(
        `/api/auth/ValidateUsername${queryString}`,
        qs.stringify({
          username: null,
          password: null,
        }),
        {
          ...identityAuthCodeFlowConfig,
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
        },
      );
      window.location = resp.headers.location;
      return;
    } catch (err) {
      if (
        err.response
        && (err.response.status === 401 || err.response.status === 405 || err.response.status === 400)
      ) {
        // if we fail due to no valid cookie, we want to indicate to continue
        // with cookie retrieval
        return;
      }
      // this will get caught and logged by the calling method
      throw err;
    }
  },
  async validateUsername(username, queryString) {

    try {
      const resp = await axios.post(
        `/api/auth/ValidateUsername${queryString}`,
        qs.stringify({
          username: username
        }),

        {
          ...identityAuthCodeFlowConfig,
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
        },
      );

      if (resp.headers.location) {
        window.location = resp.headers.location;
      }

      return resp.data;

    }
    catch (err) {
      throw err;
    }
  },

  async oauthLogin(username, password, queryString) {
    try {
      const resp = await axios.post(
        `/api/auth/login${queryString}`,
        qs.stringify({
          username,
          password,
        }),
        {
          ...identityAuthCodeFlowConfig,
          ...{
            withCredentials: true,
            headers: {
              ...identityAuthCodeFlowConfig.headers,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        },
      );

      if (resp.headers.location) {
        localStorage.setItem('isMobilePrompt', 1);
        localStorage.setItem('oAuthRedirectUrl', resp.headers.location);
      }
      return resp.data;
    } catch (err) {
      throw err;
    }
  },
  async sendOtp(hstreamId, email, phone, verificationMethod, queryString) {
    try {
      const resp = await axios.post(
        `/api/auth/otp/send${queryString}`,
        {
          message: {
            hstreamId,
            email,
            phone,
            verificationMethod
          }
        },
        identityClientConfig,
      );
    } catch (err) {
      throw err;
    }
  },
  async confirmOtp(hstreamId, verification, queryString) {
    const payload = {
      hstreamId: hstreamId,
      code: verification,
    };
    try {
      const resp = await axios.post(
        `/api/auth/otp/verify${queryString}`,
        JSON.stringify(payload),
        {
          ...identityAuthCodeFlowConfig,
          ...{
            withCredentials: true,
            headers: {
              ...identityAuthCodeFlowConfig.headers,
            },
          },
        },
      )
      if (resp.headers.location) {
        localStorage.setItem('isMobilePrompt', 1);
        localStorage.setItem('oAuthRedirectUrl', resp.headers.location);
      }
      return;

    } catch (err) {
      throw err;
    }
  },
  async fetchCustomBrandingData(clientId) {
    try {
      const resp = await axios.get(`/api/applications/${clientId}/getcustombranding`, identityClientConfig);
      return resp.data;
    } catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  async recoverPrimaryEmail(email) {
    try {
      const response = await axios.get(`/api/auth/recoveraccount?secondaryContact=${email}`, { ...identityAuthCodeFlowConfig });
      return response.data;
    } catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  //this will call getCurrentSession Api method to check csrf cookie session
  async getCurrentSession() {
    try {
      const resp = await axios.get(`/api/auth/getCurrentSession`,
        {
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
          ...identityAuthCodeFlowConfig,
        });
      localStorage.setItem('accessTokenId', resp.data.accessToken);
      return resp;
    } catch (err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  //this will call logOut Api method to get the current user logged out and will clear current user session
  async logOut(response_type, client_id) {
    try {
      let endpointUrl = `/api/auth/Signout?response_type=${response_type}`
      if (client_id != null && client_id != undefined && response_type != null && response_type != undefined)
        endpointUrl = `/api/auth/Signout?response_type=${response_type}&client_id=${client_id}`

      else if (client_id != null && client_id != undefined) {
        endpointUrl = `/api/auth/Signout?client_id=${client_id}`
      }

      else if (response_type != null && response_type != undefined) {
        endpointUrl = `/api/auth/Signout?response_type=${response_type}`
      }

      const response = await axios.get(
        endpointUrl,
        {
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
          ...identityAuthCodeFlowConfig,
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  },

  async oAuthClientSignOut(logout_uri, client_id) {
    try {
      let endpointUrl = `/api/auth/OauthClientSignout?client_id=${client_id}`
      if (logout_uri)
        endpointUrl = `/api/auth/OauthClientSignout?client_id=${client_id}&logout_uri=${logout_uri}`
      const response = await axios.get(
        endpointUrl,
        {
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
          ...identityAuthCodeFlowConfig,
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },

};

export default identityClient;

export { identityClientConfig, identityAuthCodeFlowConfig };
