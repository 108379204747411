import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { normalizePhone } from 'components/fields/PhoneNumberField';
import errorMessages from 'helpers/errorMessages';

const FormatErrorMessage = ({ error, recoverEmail, recoverError }) => {
  error = error.split('|||');
  const history = useHistory();
  const username = error[1] || false;

  if (username) {
    return (
      <Alert color="danger"
        className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'>
        <span>
          Before you can sign in you must{' '}
          <Link
            className="alert-link"
            style={{ textDecoration: 'underline' }}
            to={{
              pathname: '/account/verify/',
              search: window.location.search,
              state: { username: username },
            }}
          >
            verify your hStream ID
          </Link>.
        </span>
      </Alert>
    )
  }

  if (error[0] === errorMessages.forceResetPassword.message || error[0] === errorMessages.expiredPassword.message) {
    if (recoverEmail != null) {
      if (recoverEmail.indexOf('@') === -1) {
        recoverEmail = normalizePhone(recoverEmail);
      }
    }
    const toResetPassword = {
      pathname: "/account/forgot-password",
      search: location.search,
      state: {
        forceResetPassword: true,
        telecom: recoverEmail
      }
    }

    const ForgotPassword = () => {
      history.push(toResetPassword);
    }

    return (
      <Alert
        color="danger"
        className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'
      >
        <span id="errorMessage">
          {error[0]} Please reset your password by clicking on this
          <Button
            id="link-trigger"
            onClick={ForgotPassword}
            color="link"
            style={{
              padding: '0px 3px 0px 3px',
              fontSize: '.875rem',
              verticalAlign: 'baseline'
            }}
          >
            Link.
          </Button>
        </span>
      </Alert>
    )
  }

  if (recoverEmail && (recoverEmail.email || recoverEmail.phoneNumber)) {
    return (
      <Alert
        color="danger"
        className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'
      >
        <span id="customrecoverErrorMessage">
          The email or mobile number you entered is not set as your primary email or mobile number. {' '}
        </span>
        <Link
          to={{
            pathname: '/account/recover-account/',
            search: window.location.search,
            state: { maskEmail: recoverEmail },
          }}
          id="recoverLink"
          className="btn btn-sm btn-danger justify-content-sm-center my-auto ml-2"
          style={{ whiteSpace: 'nowrap' }}>
          Recover account
        </Link>
      </Alert>
    )
  }
  if (error[0] === errorMessages.accountLocked.message) {
    return (
      <Alert
        color="danger"
        className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'
      >
        <span id="customAccountLockedMessage">
          {error[0]}
        </span>
      </Alert>
    )
  }
  else if (error[0] === errorMessages.invalidLogin.message) {
    return (
      <Alert color="danger" className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'>
        <span id="customAuthErrorMessage">{error[0]}</span>
        <Link
          to='/account/need-help'
          id="needHelpLink"
          className="btn btn-sm btn-danger justify-content-sm-center my-auto"
          style={{ whiteSpace: 'nowrap' }}
        >
          Need help?
        </Link>
      </Alert>
    )
  }
  else if (error[0] === errorMessages.noActiveSession.message) {
      return (
          <Alert color="danger" className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'>
              <span id="sessionErrorMessage">
                  {error[0]} 
              </span>
              <Link
                  to={{
                      pathname: '/account/sign-in',
                      search: window.location.search,
                      state: {}
                  }}
                  id="signInLink"
                  className="btn btn-sm btn-danger justify-content-sm-center my-auto ml-2"
                  style={{ whiteSpace: 'nowrap' }}>
                  Sign in again
              </Link>
          </Alert>
      )
  }
  
  if (recoverError) {
    return (
      <Alert color="danger" className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'>
        <span id="customAuthErrorMessage">{recoverError}</span>
        <Link
          to='/account/need-help'
          id="needHelpLink"
          className="btn btn-sm btn-danger justify-content-sm-center my-auto"
          style={{ whiteSpace: 'nowrap' }}
        >
          Need help?
        </Link>
      </Alert>
    )
  } 
  else if (error[0]) {
      return (
          <Alert color="danger" className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between'>
               <span id="customErrorMessage">
                  {error[0]}
              </span>
          </Alert>
      )
  }
  return <div />
};

FormatErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  recoverEmail: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  recoverError: PropTypes.string,
};

export default FormatErrorMessage;
