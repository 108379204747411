import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { togglePhonePrompt } from 'redux-modules/auth/actions';
import AuthWrapper from 'components/AuthWrapper';
import { Alert, Button } from 'reactstrap';
import store from 'store';
import PhonePromptForm from './PhonePromptForm';
import AssistanceModal from 'components/AssistanceModal';
import identityClient from '../../clients/identityClient';
import newRelic from '../../helpers/newRelic';
import redirect from '../../helpers/redirect';
import ssoIntegrationOptionsShape from '../../shapes/ssoIntegrationOptionsShape';
import VerificationCodeForm from '../../containers/Verify/VerificationCodeForm';
import PhonePromptModal from './PhonePromptModal';

const PhonePrompt = (props) => {
  const [isRequestPending, toggleIsRequestPending] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [phoneForTryAgain, updatePhoneForTryAgain] = useState(undefined);
  const [displayAssistance, setDisplayAssistance] = useState(false);
  const [displayPhonePrompt, setDisplayPhonePrompt] = useState(false);
  const [isResendCode, setIsResendCode] = useState("sent");
  const [error, toggleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    ssoIntegrationOptions,
  } = props;
  
  const { originalQueryString } = ssoIntegrationOptions;
  
  const state = store.getState();
  const { appSettingMobilePromptConfigurationDays } = state.auth.aws;

  const setPhonePromptDateForFuture = async () => {
    const newPhonePromptDate = new Date();
    newPhonePromptDate.setDate(newPhonePromptDate.getDate() + appSettingMobilePromptConfigurationDays);  
    identityClient.setAccountPreference(
      'hstreamid_phonepromptdate',
      newPhonePromptDate.toISOString(),
      originalQueryString
    );
  };

  const verifyMobile = async ( code ) => {
    const AccountData = await identityClient.myAccount(
      originalQueryString
    );
    await identityClient.verifyPhone(
      AccountData.hstreamId,
      code
    );
  };

  // DisplayAssistant
  const toggleDisplayAssistance = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e && e.preventDefault();
    setDisplayAssistance(!displayAssistance)
  };
  // DisplayAssistant

  const toggleDisplayPhonePrompt = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e && e.preventDefault();
    setDisplayPhonePrompt(!displayPhonePrompt)
  };

  const onSubmit = async (values) => {
    toggleIsRequestPending(true);
    try {
      updatePhoneForTryAgain(values.phone);
      await identityClient.addPhoneNumber(values.phone, originalQueryString);
      toggleError(false);
      setVerificationSent(true);
      toggleIsRequestPending(false);
    } catch (err) {
        newRelic.noticeError(err);
        toggleIsRequestPending(false);
        setErrorMessage(err.response.data);
        toggleError(true);
    }
  };
  
  const onConfirm = async (values) => {
    toggleIsRequestPending(true);
    try {
      await verifyMobile(values.verification);
      if (localStorage.getItem('isMobilePrompt') == 1){    
        localStorage.setItem('isMobilePrompt',0);
        redirect.to(`${localStorage.getItem('oAuthRedirectUrl')}`);
      }
      else
      {
      props.togglePhonePromptConnect(false);
      }
    } catch (err) {
        newRelic.noticeError(err); 
        setErrorMessage(err.response.data);
        toggleIsRequestPending(false);
        toggleError(true);
    }
  };

  const handleRemindMeLater = async () => {
    toggleIsRequestPending(true);
    try {
      await setPhonePromptDateForFuture();
      if (localStorage.getItem('isMobilePrompt') == 1){    
        localStorage.setItem('isMobilePrompt',0);
        redirect.to(`${localStorage.getItem('oAuthRedirectUrl')}`);
      }
      else
      {
        props.togglePhonePromptConnect(false);
      }
    } catch (err) {
      newRelic.noticeError(err);
      console.log(err);
      toggleIsRequestPending(false);
    }
  };

  return (
    <>
    <AuthWrapper title={verificationSent? "Verify your Code" : "Enter a mobile number for Account Recovery"}>
    {!isRequestPending && error && (
      <>{errorMessage && errorMessage.status && (errorMessage.status == 400 || errorMessage.status == 409)? <Alert color="danger">          
      {errorMessage.detail}
    </Alert>: <Alert color="danger">  
          We encountered an issue trying to add your mobile number. Please{' '}
          <Button
            id="contact-modal-trigger"
            onClick={toggleDisplayAssistance}
            color="link"
            style={{
              fontSize: '14px',
              marginBottom: '3px',
              padding: '0'
            }}

          >
            contact us
          </Button>
    </Alert>}</>)}
    {verificationSent ? (        
          <div>
                {error? <></>: <>{isRequestPending? <></>:<Alert>Please enter the Verification Code {isResendCode} to your mobile number {phoneForTryAgain.substring(0,2) + 'XXXXXX' + phoneForTryAgain.substring(8)}, if the phone number exists.
                </Alert>}</>}
                <VerificationCodeForm
                  submitOnMount={false}
                  onSubmit={onConfirm}
                  isRequestPending={isRequestPending}
                  initialValues={{ username: null , verification: null }}
                  isCancelButtonRequired={true}
                  onCancel={() => {toggleError(false);setVerificationSent(false);toggleIsRequestPending(false);setIsResendCode("sent");}}
                />
            <div className="d-flex justify-content-start align-items-center flex-wrap">
              <b style={{ marginRight: '4px' }}>Need a new code?</b>
              <div className="d-flex justify-content-start align-items-center">
              <span>Resend to your</span>
              <Button
                color="link"
                size="sm"
                onClick={() => {onSubmit({phone :phoneForTryAgain});
                setIsResendCode("resent");}}
                style={{ padding: 0, margin: '0 4px', fontSize: '1em' }}
                id="send-code-phone"
                >
                Phone
                </Button>
              </div>
            </div>
          </div>
      ) : (
        <>
          <section>
            <p>
              {' '}
              Your phone number will be used for password recovery and will not be used for any other purposes without your consent.{' '}
            </p>
          </section>
          <PhonePromptForm
            isRequestPending={isRequestPending}
            onSubmit={onSubmit}
          />
          <hr />
          <section className="d-flex align-items-center">
            <div className="RemindMeLater"
            style={{justifyContent: 'flex-end'}}>
              <Button
                color="link"
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: "grey"
                }}
                onClick={toggleDisplayPhonePrompt}
              >
                Skip - Continue to Application
              </Button>
            </div>
          </section>
        </>
      )}
    </AuthWrapper>
    <AssistanceModal
      displayAssistance={displayAssistance}
      toggleDisplayAssistance={toggleDisplayAssistance}
    />    
    <PhonePromptModal
      displayPhonePrompt={displayPhonePrompt}
      toggleDisplayPhonePrompt={toggleDisplayPhonePrompt}
      skipPrompt={handleRemindMeLater}
    />
    </>
  );
};

PhonePrompt.propTypes = {
  togglePhonePromptConnect: PropTypes.func.isRequired,
  ssoIntegrationOptions: ssoIntegrationOptionsShape.isRequired,
};

PhonePrompt.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  togglePhonePromptConnect: togglePhonePrompt,
};

const ConnectedPhonePrompt = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhonePrompt);

export default ConnectedPhonePrompt;
export { PhonePrompt as PurePhonePrompt };
