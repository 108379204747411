import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Checkbox from '../../core/CheckboxGroup/Checkbox';
import { FormGroup } from 'reactstrap';
import { required } from './validate';

const TermsFieldComponent = ({ input, meta, isRequestPending }) => {
  const { value: checked, ...inputProps } = input;
  return (
    <FormGroup>
      <Checkbox
        {...inputProps}
        checked={checked}
        disabled={isRequestPending}
        value="terms"
      >
        I agree with the{' '}
        <a
          href="https://www.healthstream.com/operational-pages/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of use
        </a>
        {'.'}
      </Checkbox>
      {meta.touched && meta.error && (
        <div className="d-block invalid-feedback">{meta.error}</div>
      )}
    </FormGroup>
  );
};

const TermsField = ({ disabled }) => (
  <Field
    name="terms"
    component={TermsFieldComponent}
    disabled={disabled}
    validate={[required]}
  />
);

TermsField.propTypes = {
  disabled: PropTypes.bool,
};

TermsField.defaultProps = {
  disabled: false,
};

export default TermsField;
export { TermsFieldComponent };
