import React from 'react';
import PropTypes from 'prop-types';
import {oAuthSignOutAndRedirect } from 'redux-modules/auth/actions';
import { connect } from 'react-redux';
import AuthWrapper from 'components/AuthWrapper';
import * as selectors from '../../redux-modules/auth/selectors';


class OauthClientSignOut extends React.Component {
  componentDidMount() {
    const {
      oAuthsignOutAndRedirectConnect,
      redirectUri,
      redirectState,
      responseType,
      clientId,
    } = this.props;

    oAuthsignOutAndRedirectConnect(redirectUri, clientId, responseType, redirectState);

  }

  render() {
    const { user, redirectUri } = this.props;

    if (!user && redirectUri) {
      return <div />;
    }
    return <AuthWrapper title="You are being signed out..." />;
  
}
}

OauthClientSignOut.propTypes = {
  oAuthsignOutAndRedirectConnect: PropTypes.func.isRequired,
  redirectUri: PropTypes.string,
  redirectState: PropTypes.string,
  responseType: PropTypes.string,
};

OauthClientSignOut.defaultProps = {
  redirectUri: undefined,
  redirectState: undefined,
  responseType: undefined,
};

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
});
const mapDispatchToProps = {
  oAuthsignOutAndRedirectConnect: oAuthSignOutAndRedirect,
};
const ConnectedSignOut = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OauthClientSignOut);

export default ConnectedSignOut;
export { OauthClientSignOut as PureSignOut };
