import React from 'react';
import PropTypes from 'prop-types';
import AuthWrapper from 'components/AuthWrapper';
import redirect from 'helpers/redirect';
const Dashboard = ({ redirectUri }) => {
    redirect.toRedirectUri(redirectUri, null, 'access', null);
    return <AuthWrapper>Redirecting&hellip;</AuthWrapper>;
};
Dashboard.propTypes = {
    redirectUri: PropTypes.string.isRequired,
};
export default Dashboard;
export { Dashboard as PureDashboard };