import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody,ModalHeader, Button } from 'reactstrap';

const PhonePromptModal = ({ displayPhonePrompt, toggleDisplayPhonePrompt, skipPrompt }) => {
  return (
       <Modal isOpen={displayPhonePrompt} centered={true}>
       <ModalHeader
        style={{
          backgroundColor: '#F5F7FA',
          borderBottom: '1px solid #F5F7FA',
        }}
        toggle={toggleDisplayPhonePrompt}
      >
      Are you sure you want to skip and continue?
      </ModalHeader>
      <ModalBody>
        <div className="modalBodyFlex">
         
          <div style={{ marginBottom: '20px', fontSize: 'small'}}>
            <span style={{fontWeight: 'bold'}}>ATTENTION! </span>Providing your mobile number is highly recommended in the event you need to recover your password.
          </div>
          <div style={{textAlign: 'center',marginTop: '10px'}}>
          <Button
                color="primary"
                style={{
                  marginLeft: 'auto',
                  marginBottom: 'auto',
                  marginTop: '.25em',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
                onClick={() => {toggleDisplayPhonePrompt()}}
                id="enter-mobile"
                >
                Enter Mobile number
                </Button>
          </div>
          <div style={{marginTop: '20px', marginBottom: '10px', fontSize: 'small'}}>
          Your phone number will be used for password recovery and will not be used for any other purposes without your consent.
          </div>
          <div style={{textAlign: 'center'}}>
          <Button
                color="link"
                style={{
                  marginTop: '10px',
                  fontSize: '12px',
                  color: 'grey',
                }}
                onClick={() => {skipPrompt()}}
                id="continue-application"
                >
                Skip - Continue to Application
                </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

PhonePromptModal.propTypes = {
  toggleDisplayPhonePrompt: PropTypes.func.isRequired,
  displayPhonePrompt: PropTypes.bool.isRequired,  
  skipPrompt: PropTypes.func.isRequired,
};

export default PhonePromptModal;
